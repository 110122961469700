const postCssPlugins = require('./postcss-config.js');

module.exports = {
  siteMetadata: {
    siteUrl: 'https://mamoncillo.xyz/',
    url: 'https://mamoncillo.xyz/',
    title: 'Mamoncillo',
    subtitle: 'Un podcast para hablar entre paisanos y amigos. Desde Roldanillo, Colombia.',
    copyright: 'Un Mamoncillo © 2020',
    disqusShortname: 'mamoncillo-xyz',
    menu: [
      {
        label: 'Inicio',
        path: '/'
      },
      {
        label: 'Acerca de',
        path: '/acerca-de/'
      }
    ],
    author: {
      name: 'Mamoncillo',
      email: 'hola@mamoncillo.xyz',
      rss: 'https://anchor.fm/s/3929177c/podcast/rss',
      spotify: 'https://open.spotify.com/show/4fvGiNih2sqzCMDpsQ60Hz',
      telegram: '',
      twitter: '',
      github: '',
      ivoox: '',
      facebook: 'https://facebook.com/mamoncillopodcast',
      instagram: 'https://instagram.com/mamoncillopodcast',
      itunes: 'https://podcasts.apple.com/us/podcast/mamoncillo/id1518436716?uo=4',
      google: 'https://podcasts.google.com/feed/aHR0cHM6Ly93d3cuc3ByZWFrZXIuY29tL3Nob3cvNDQ1Mjk4Ni9lcGlzb2Rlcy9mZWVk'
    }
  },
  pathPrefix: '/',
  plugins: [
    {
      resolve: 'gatsby-source-filesystem',
      options: {
        path: `${__dirname}/src/pages`,
        name: 'pages'
      }
    },
    {
      resolve: 'gatsby-source-feed',
      options: { feedURL: 'https://anchor.fm/s/3929177c/podcast/rss' }
    },
    {
      resolve: 'gatsby-plugin-google-analytics',
      options: { trackingId: 'UA-179381291-1' }
    },
    {
      resolve: 'gatsby-transformer-remark',
      options: {
        plugins: [
          {
            resolve: 'gatsby-remark-images',
            options: { maxWidth: 960 }
          },
          {
            resolve: 'gatsby-remark-responsive-iframe',
            options: { wrapperStyle: 'margin-bottom: 1.0725rem' }
          },
          'gatsby-remark-prismjs',
          'gatsby-remark-copy-linked-files',
          'gatsby-remark-smartypants'
        ]
      }
    },
    'gatsby-transformer-sharp',
    'gatsby-plugin-sharp',
    {
      resolve: 'gatsby-plugin-google-fonts',
      options: { fonts: ['nunito:400,400i,500,700'] }
    },
    {
      resolve: 'gatsby-plugin-sitemap',
      options: {
        query: `
        {
          site {
            siteMetadata {
              siteUrl
            }
          }
          allSitePage(
            filter: {
              path: { regex: "/^(?!/404/|/404.html|/dev-404-page/)/" }
            }
            ) {
              edges {
                node {
                  path
                }
              }
            }
          }`,
          output: '/sitemap.xml',
          serialize: ({ site, allSitePage }) =>
          allSitePage.edges.map((edge) => {
            return {
              url: site.siteMetadata.siteUrl + edge.node.path,
              changefreq: 'daily',
              priority: 0.7
            };
          })
        }
      },
      'gatsby-plugin-offline',
      'gatsby-plugin-catch-links',
      {
        resolve: `gatsby-plugin-favicon`,
        options: {
          logo: './src/assets/images/favicon.png',
          // WebApp Manifest Configuration
          appName: 'Mamoncillo el Podcast',
          appDescription: null,
          developerName: null,
          developerURL: null,
          dir: 'auto',
          lang: 'es-CO',
          background: '#fff',
          theme_color: '#fff',
          display: 'standalone',
          orientation: 'any',
          start_url: '/',
          version: '1.0',
          
          icons: {
            android: true,
            appleIcon: true,
            appleStartup: true,
            coast: false,
            favicons: true,
            firefox: true,
            yandex: false,
            windows: false
          }
        }
      },
      'gatsby-plugin-react-helmet',
      {
        resolve: 'gatsby-plugin-sass',
        options: {
          postCssPlugins: [...postCssPlugins],
          cssLoaderOptions: {
            camelCase: false,
          }
        }
      },
      // 'gatsby-plugin-postcss'
    ]
  };
  