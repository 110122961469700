import React from 'react';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import EpisodeTemplateDetails from '../components/EpisodeTemplateDetails';
import slugify from 'slugify';

const excerpt = (val) => {
  if (val.length <= 600) {
    return val;
  }
  return `${val.replace(/^(.{600}[^\s]*).*/, "$1")}...`; 
};

class EpisodeTemplate extends React.Component {
  render() {
    const { title, subtitle, siteUrl } = this.props.data.site.siteMetadata;
    const post = this.props.data.allPodcastFeedItem;
    let {
      title: postTitle, description, enclosure, image,
    } = post.edges[0].node;
    const slug = `${siteUrl}${slugify('/'+postTitle, {lower: true, remove: /[$*_+~.()'"!\-:@]/g})}`;
    description = excerpt(post.edges[0].node.description);
    postTitle = `${postTitle} - ${title}`;
    return (
      <div>
        <Helmet>
          <title>{postTitle}</title>
          <meta name="description" content={description} />
          <meta property="og:url" content={slug} />
          <meta property="og:type" content="music.song" />
          <meta property="og:audio" content={enclosure.url} />
          <meta property="og:type" content={enclosure.type} />
          <meta property="og:audio:secure_url" content={enclosure.url} />
          <meta property="og:title" content={postTitle} />
          <meta property="og:description" content={description} />
          <meta property="og:image" content={image} />
        </Helmet>
        <EpisodeTemplateDetails {...this.props} />
      </div>
    );
  }
}

export default EpisodeTemplate;

export const pageQuery = graphql`
  query currentPostQuery($guid: String!) {
    site {
      siteMetadata {
        title
        subtitle
        copyright
        siteUrl
        author {
          name
          spotify
          instagram
          facebook
          google
          itunes
        }
      }
    }
    allPodcastFeedItem (limit: 1, filter: { guid: { eq: $guid } }){
      edges {
        node {
          guid,
          title,
          description,
          published,
          link,
          image,
          enclosure {
            url,
            filesize,
            type
          }
        }
      }
    }
  }
`;